<template>
    <div class='grid'>
        <AdminToast></AdminToast>
        <ConfirmDialog></ConfirmDialog>
        <InvoiceReport :report='report'></InvoiceReport>
        <div class='col-12 md:col-6 lg:col-12'>

            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>
                    <div class='col-12'>
                        <Toolbar class='mb-2'>
                            <template v-slot:start>

                                <div>
                                    <Calendar id='selectedMonth' view='month' dateFormat='MM - yy' :showIcon='true'
                                              :showButtonBar='true' v-model='selectedPeriod'
                                              @date-select='selectedPeriodChanged'></Calendar>
                                </div>

                            </template>

                            <template v-slot:end>

                                <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success'
                                        @click='exportExcel'></Button>
                            </template>
                        </Toolbar>
                    </div>

                </div>

                <DataTable
                    :value='dataList'
                    :paginator='true'
                    class='p-datatable-gridlines'
                    :rows='6'
                    dataKey='id'
                    :rowHover='true'
                    v-model:filters='filters'
                    filterDisplay='menu'
                    :loading='loadingIndicator'
                    :filters='filters'
                    responsiveLayout='scroll'
                    :globalFilterFields="['companyName','category','invoiceType','documentType','description']">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <h5 class='mb-3 mt-3'>Gelir - Gider Listesi</h5>

                            <span class='p-input-icon-left mb-2'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Veri bulunamadı.
                    </template>

                    <template #loading>
                        Taşeron firma listesi yükleniyor. Lütfen bekleyin.
                    </template>


                    <Column field='companyName' header='Firma İsmi' filterField='companyName'
                            :showFilterMatchModes='false' style='width:18rem'>
                    </Column>


                    <Column field='category' header='Kategori' filterField='category' :showFilterMatchModes='false' style='width:14rem'>
                    </Column>

                    <Column field='documentDate' header='Evrak Tarihi' filterField='documentDate' :showFilterMatchModes='false' style='width:8rem'>
                        <template #body='{data}'>
                            {{ formatDate(data.documentDate) }}
                        </template>
                    </Column>


                    <Column field='invoiceType' header='Evrak Kategorisi' filterField='invoiceType' :showFilterMatchModes='false' style='width:8rem'>
                        <template #body='{data}'>
                            {{ formatInvoiceType(data.invoiceType) }}
                        </template>
                    </Column>

                    <Column field='documentType' header='Evrak Tipi' filterField='documentType' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column field='description' header='Açıklama' filterField='description' :showFilterMatchModes='false'>
                    </Column>

                    <Column field='invoiceTotal' header='Tutar' filterField='invoiceTotal' :showFilterMatchModes='false' style='width:10rem'>
                        <template #body='{data}'>
                            <span class="text-slate-600 font-bold">{{ formatNumber(data.invoiceTotal) }} TL</span>
                        </template>
                    </Column>

                    <Column field='taxTotal' header='KDV' filterField='taxTotal' :showFilterMatchModes='false' style='width:10rem'>
                        <template #body='{data}'>
                         <span class="text-red-600 font-bold">{{ formatNumber(data.taxTotal) }} TL</span>
                        </template>
                    </Column>

                    <Column field='totalWithTax' header='Toplam Tutar' filterField='totalWithTax' :showFilterMatchModes='false' style='width:10rem'>
                        <template #body='{data}'>
                            <span class="text-green-600 font-bold">{{ formatNumber(data.totalWithTax) }} TL</span>
                        </template>
                    </Column>

                    <Column header='Görüntüle' bodyClass='text-center'  :showFilterMatchModes='false' style='width:6.5rem'>
                        <template #body='{data}'>
                            <a :href='"http://image.a1catering.com.tr/" + data.documentFile' target='_blank' v-if='data.documentFile != null && data.documentFile != ""'>
                                <Button icon='pi pi-eye' class='p-button-rounded p-button-primary' />
                            </a>
                        </template>
                    </Column>

                    <Column field='isApproved' header='Kontrol' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isApproved, 'text-pink-500 pi-times-circle': !data.isApproved}" @click='approveInvoice(data.id, !data.isApproved)'></i>
                        </template>
                    </Column>

                    <Column header='Düzenle' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-primary'
                                    @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                    <Column header='Sil' bodyClass='text-center' style='width:6.5rem' :exportable='false'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-trash' class='p-button-rounded p-button-danger'
                                    @click='deleteItem(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import store from '@/store';
import InvoiceService from '@/services/invoiceService';
import { getActiveProjectId } from '@/pages/common/commonConstantFunctions';
import { formatNumber } from 'chart.js/helpers';
import AdminToast from '@/components/shared/toast/adminToast.vue';
import InvoiceReport from '@/pages/invoice/invoiceReport.vue';
import { showErrorMessage, showSuccessMessage } from '@/components/shared/toast/toastFunction';

export default {
    components: { AdminToast, InvoiceReport },
    data() {
        return {
            dataList: [],
            filters: null,
            loadingIndicator: true,
            selectedPeriod: {},
            invoiceType: '',
            projectId: 0,
            report: {},
        };
    },
    invoiceService: null,
    created() {
        this.selectedPeriod = moment().toDate();

        this.projectId = getActiveProjectId();
        this.invoiceService = new InvoiceService();
        this.initFilters();
        this.loadingIndicator = true;
        this.invoiceType = this.$route.params.invoiceType;
    },
    async mounted() {
        await this.loadGrid();
        this.loadingIndicator = false;
    },
    methods: {
        formatNumber,
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        async exportExcel() {
            let projectId = store.getters.getActiveProject.id;
            if (this.invoiceType) {
                const excelExports = await this.invoiceService.getInvoicesWithProjectIdWithPeriodWithInvoiceTypeExcel(projectId, this.invoiceType, this.formatPeriod(this.selectedPeriod));
                let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Faturalar " + moment(this.paymentDate).format('DD.MM.YYYY') + ".xlsx";
                link.click();

            } else {
                const excelExports = await this.invoiceService.getInvoicesWithProjectIdWithPeriodExcel(projectId, this.formatPeriod(this.selectedPeriod));
                let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Faturalar " + moment(this.paymentDate).format('DD.MM.YYYY') + ".xlsx";
                link.click();
            }
        },
        async loadGrid() {
            let projectId = store.getters.getActiveProject.id;
            if (this.invoiceType) {
                const response = await this.invoiceService.getInputInvoicesWithProjectIdWithInvoiceType(projectId, this.invoiceType, this.formatPeriod(this.selectedPeriod));
                this.dataList = response.data;

            } else {
                const response = await this.invoiceService.getInputInvoicesWithProjectId(projectId, this.formatPeriod(this.selectedPeriod));
                this.dataList = response.data;
            }

            const reportResponse = await this.invoiceService.getInvoiceReports(projectId, this.formatPeriod(this.selectedPeriod));
            this.report = reportResponse.data;

        },
        clearFilter() {
            this.initFilters();
        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        edit(item) {
            this.$router.push({ name: 'editInvoice', params: { invoiceType: item.invoiceType ,  invoiceId: item.id } });
        },
        approveInvoice(id, newStatus) {
            this.$confirm.require({
                message: 'Belge Onay Statüsü Değişecek Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    await this.invoiceService.updateApproveStatus(id, newStatus);
                    await this.loadGrid();
                },

            });
        },
        async deleteItem(item) {
            this.$confirm.require({
                message: 'Belge silinecektir onaylıyormusunuz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let invoiceResponse = await this.invoiceService.getInvoiceById(item.id);
                    console.log(invoiceResponse);
                    if(invoiceResponse.isSuccess)
                    {

                        let deleteResponse = await this.invoiceService.deleteInvoiceDocument(invoiceResponse.data.documentFile);
                        if(deleteResponse.isSuccess)
                        {
                            let response = await this.invoiceService.deleteById(item.id, '');
                            if (response.isSuccess) {
                                showSuccessMessage(this, 'Silme işlemi başarılı');
                                await this.loadGrid();
                            }
                        }
                        else {
                            showErrorMessage(this, "Dosya silinemedi");
                        }
                    }
                    else
                    {
                        showErrorMessage(this,"Hata");
                    }



                },

            });
        },
        selectedPeriodChanged() {
            this.loadGrid();
        },
        formatPeriod(value) {
            let newDate = moment(value);
            return newDate.format('MM-yy');
        },
        formatInvoiceType(value) {
            if (value == 'InputInvoice') {
                return 'Gider';
            } else {
                return 'Gelir';
            }
        },
    },
};
</script>

<style scoped lang='scss'>
//@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}
</style>
